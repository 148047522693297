
import Vue from 'vue'
import ModalTemplate from '../modal-template/template.vue'

export default {
  el: '#payment-form-due',
  components: {
    [ModalTemplate.name]: ModalTemplate,
  },
  data: {
    achAccountName: null,
    achAccountType: null,
    achToken: null,
    bridgeTerms: false,
    address1: null,
    city: null,
    state: null,
    zip: null,
    poNumber: null,
    Notes:null,
    ccToken: null,
    ccExp: null,
    cvv: null,
    paymentType: 'credit',
    appliedCredit: 0,
    creditAmount: null,
    billing_address: true,
    referenceNo: null
  },
  created: function(){
    var organization_address = $('#organization-address').data('address');
    if(organization_address){
      this.address1 = organization_address.street1;
      this.city = organization_address.city;
      this.state = organization_address.state;
      this.zip = organization_address.zip;
    }
  },
  mounted() {
    this.$nextTick(function() {
      window.addEventListener('message', this.handleTokenizingResponse);
    });
    $('#total_without_extra_charge').hide();
  },
  beforeDestroy() {
    window.removeEventListener('message', this.handleTokenizingResponse);
  },
  computed: {
    disableForm() {
      const termsFieldExists = document.querySelector('input#payment_terms_and_conditions');
      var totalCost = document.getElementById("total_cost_show").value;
      const baseDataInvalid = (!this.bridgeTerms && termsFieldExists) ||
                              !this.address1 ||
                              !this.city ||
                              !this.state ||
                              !(/(^\d{5}$)|(^\d{5}-\d{4}$)/).test(this.zip);
      const achDataInvalid = !this.achAccountName ||
                             !this.achToken ||
                             !this.achAccountType;

      const ccDataInvalid = !this.ccToken ||
                            !this.ccExp ||
                            !this.cvv;

       if(totalCost == "0.00"){
        return true;
      }else if (this.paymentType == null && this.creditsCoverTotalCost() && ((termsFieldExists && this.bridgeTerms) || !termsFieldExists) ) {
        return false;
      }else if (this.paymentType == 'credit') {
        return baseDataInvalid || ccDataInvalid;
      }else if(this.paymentType == 'ach'){
        return baseDataInvalid || achDataInvalid;
      }else{
        return true;
      }
    },
    payingWithCredit() {
      return this.paymentType == 'credit';
    },
    payingWithACH() {
      return this.paymentType == 'ach';
    }
  },
  methods: {
    unableToAddCredit: function () {
      if(isNaN(this.creditAmount) || Number(this.creditAmount) <= 0 || !this.isNumberUntilTwoDecimalPlaces(this.creditAmount)){
        return true;
      }

      var totalCost = this.totalPurchasedWithLateFee().toFixed(2);
      let amount = Number(this.creditAmount);
      return amount > this.availableCredits() || amount + this.totalPaid() + this.appliedCredit > totalCost;
    },
    isNumberUntilTwoDecimalPlaces: function(value){
      // Obs: All the conversions below are necessary because sometimes in JS: "17.1 * 100 = 1710.0000000000002"
      let number = parseFloat((Number(value) * 100).toFixed(3));
      return Number.isSafeInteger(number);
    },
    addCredit: function () {
      var totalCost_without_extra_fee = document.getElementById("total_value").value;
      if (this.unableToAddCredit()) return true;
      this.appliedCredit += Number(this.creditAmount);
      this.creditAmount = null;
      if (this.appliedCredit == totalCost_without_extra_fee){
        this.setClassesFromElement("credit_card", "btn disabled");
        this.setClassesFromElement("e_check", "btn disabled");
      }
    },
    setClassesFromElement: function(idElement, classes){
      let eCheckButton = document.getElementById(idElement);
      if(eCheckButton){
        eCheckButton.className = classes;
      }
    },
    deleteCredit: function(){
      this.appliedCredit = 0;
      this.setClassesFromElement("credit_card", "btn");
      this.setClassesFromElement("e_check", "btn");
    },
    availableCredits() {
      var currentCredits = $("#total-credits").data('creditsAvailable');
      return (currentCredits - this.appliedCredit);
    },
    creditsCoverTotalCost() {
      return (this.appliedCredit > 0 && this.calculateTotalWithoutCreditCardFee() == 0);
    },
    setNewPaymentMethod(newMethod) {
      const hiddenField = document.getElementById('payment_payment_type');
      hiddenField.value = newMethod;
      this.paymentType = newMethod;
    },
    selectCreditCard() {
      $('#extra_charge_div').show();
      $('#total_with_extra_charge').show();
      $('#total_without_extra_charge').hide();
      this.setNewPaymentMethod('credit');
      document.getElementById('total_cost_show').value = document.getElementById("total_cost").value;
      this.billing_address = true
      document.getElementById("payment_method").innerHTML = 'Payment Method: <b>CREDIT CARD</b>'
    },
    formattedTotalWithCreditCard(){
      return this.formatUSAMoneyValue(this.calculateTotalWithCreditCardFee());
    },
    calculateTotalWithCreditCardFee(){
      return this.calculateTotalWithoutCreditCardFee() + this.calculateCreditCardFee();
    },
    formattedTotalWithoutCreditCard(){
      return this.formatUSAMoneyValue(this.calculateTotalWithoutCreditCardFee());
    },
    calculateTotalWithoutCreditCardFee(){
      return this.totalPurchasedWithLateFee() - this.totalPaid() - this.appliedCredit;
    },
    totalPurchasedWithLateFee(){
      let totalPurchased = Number(document.getElementById("total_value").value);
      let lateFee = Number(document.getElementById("late_fee").value);
      return totalPurchased + lateFee;
    },
    totalPaid(){
      let creditsAppliedBefore = Number(document.getElementById("credits_applied_before").value);
      let cashPayment = Number(document.getElementById("paid_amount").value);
      return creditsAppliedBefore + cashPayment;
    },
    calculateCreditCardFee(){
      let extra_fee = Number(document.getElementById("extra_fee").value);
      return this.calculateTotalWithoutCreditCardFee() * extra_fee;
    },
    creditCardFeeFormated(){
      return this.formatUSAMoneyValue(this.calculateCreditCardFee());
    },
    formatUSAMoneyValue(value){
      return value.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    },
    selectACHAccount() {
      $('#extra_charge_div').hide();
      $('#total_with_extra_charge').hide();
      $('#total_without_extra_charge').show();
      document.getElementById('total_cost_show').value = document.getElementById("total_value").value;
      this.setNewPaymentMethod('ach');
      this.billing_address = true
      document.getElementById("payment_method").innerHTML = 'Payment Method: <b>ECHECK/ACH</b>'
      
    },
    selectCredits() {
      var totalCost = document.getElementById("total_cost_show").value;
      $('#extra_charge_div').hide();
      $('#total_with_extra_charge').hide();
      $('#total_without_extra_charge').show();
      document.getElementById('total_cost_show').value = document.getElementById("total_value").value;
      this.billing_address = false
      this.paymentType = null;
      document.getElementById("payment_method").innerHTML = 'Payment Method: <b>CREDITS</b>'
      
    },

    submit(e) {
      if (this.creditsCoverTotalCost()) this.setNewPaymentMethod('accrued_credits');

      const form = $(this.$el);
      form.submit();
    },
    handleTokenizingResponse: function(event) {
      // Next line doesn't allow us to stub this message out in the user tests
      // if (event.origin !== "https://fts.cardconnect.com") return;
      try {
          var payload = JSON.parse(event.data);
          if (payload.hasOwnProperty('ccToken')) {
            console.log('Received Token (cc)');
            this.ccToken = payload.ccToken;
            document.getElementById('credit_card_token').value = this.ccToken;
          } else if (payload.hasOwnProperty('achToken')) {
            console.log('Received Token (ach)');
            this.achToken = payload.achToken;
            document.getElementById('ach_token').value = this.achToken;
          }
      } catch (e) {
          return false;
      }
    }
  }
}

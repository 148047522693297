/* eslint no-console: 0 */

import Vue from 'vue'
import PaymentForm from '../payment/form-due.js'

document.addEventListener('turbolinks:load', () => {
  let formDueDefined = document.getElementById("payment-form-due");
  if(formDueDefined){
    new Vue(PaymentForm);
  }
});

